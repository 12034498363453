<template>
  <main class="w-full min-h-screen flex justify-center items-center flex-col gap-3 py-3">
    <div class="w-1/2 sm:w-3/4 flex justify-center items-center flex-col gap-3">
      <h1 class="font-extrabold text-3xl uppercase text-emphasis">Santiago Soluções Tecnológicas</h1>
      <h2 class="font-extralight text-sm italic">Transformando ideias em realidade</h2>
      <h3 class="font-extralight text-2xl my-2">Bem-vindo a <a href="https://www.santiagotec.com.br"
          class="text-emphasis">santiagotec</a></h3>
      <p>
        Atualmente estamos trabalhando duro para trazer a você uma experiência incrível. Nosso site está em
        desenvolvimento ativo para garantir que possamos fornecer conteúdo de qualidade e recursos úteis.
      </p>
      <p>
        <span class="text-emphasis">Enquanto isso</span>, se você tiver alguma dúvida, sugestão ou gostaria de obter
        mais informações sobre nossos serviços, por favor, não hesite em nos contatar. Estamos ansiosos para ouvir de
        você!
      </p>
      <a href="https://api.whatsapp.com/send?phone=5515988346209" rel="noreferrer" target="_blank"
        class="text-emphasis border border-emphasis px-3 py-2 rounded-full hover:shadow-lg hover:shadow-emphasis">Whatsapp</a>
      <!-- <a href="#" rel="noreferrer" target="_blank"
        class="text-emphasis border border-emphasis px-3 py-2 rounded-full hover:shadow-lg hover:shadow-emphasis">Instagram</a> -->
      <a href="mailto:contato@santiagotec.com.br" rel="noreferrer" target="_blank"
        class="text-emphasis border border-emphasis px-3 py-2 rounded-full hover:shadow-lg hover:shadow-emphasis">Email</a>
    </div>
  </main>
</template>

<script>
export default {
  name: 'App',
  components: {}
}
</script>
